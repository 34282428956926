import { format } from "date-fns";
import { Link } from "gatsby";
import React from "react";
import { buildImageObj, getBlogUrl } from "../lib/helpers";
import { imageUrlFor } from "../lib/image-url";
import PortableText from "./portableText";

function BlogPostPreview(props) {
  return (
    <Link
      className="flex flex-wrap flex-col-reverse md:flex-row border-b border-gray pb-10 mb-10"
      to={getBlogUrl(props.slug.current)}
    >
      <div className="flex-col w-full md:w-3/5 pr-32">
        <h3 className="text-2xl md:text-3xl font-semibold leading-tight text-primary mb-2">
          {props.title}
        </h3>
        <div className="leading-normal text-md md:text-lg text-body font-small mb-2">
          {format(props.publishedAt, "MMMM Do, YYYY")}
        </div>
        {props._rawExcerpt && (
          <div className="leading-normal text-md md:text-lg text-body font-light">
            <PortableText blocks={props._rawExcerpt} />
          </div>
        )}
      </div>
      <div className="flex-col w-full md:w-2/5 mb-4 md:mb-0">
        {props.mainImage && props.mainImage.asset && (
          <img
            className="w-full"
            src={imageUrlFor(buildImageObj(props.mainImage))
              .width(600)
              .height(Math.floor((9 / 16) * 600))
              .auto("format")
              .url()}
            alt={props.mainImage.alt}
          />
        )}
      </div>
    </Link>
  );
}

export default BlogPostPreview;
