import React from "react";
import BlogPostPreview from "./blog-post-preview";

function BlogPostPreviewGrid(props) {
  return (
    <ul class="list-none m-0">
      {props.nodes &&
        props.nodes.map((node) => (
          <li key={node.id} className="m-0 p-0">
            <BlogPostPreview {...node} />
          </li>
        ))}
    </ul>
  );
}

BlogPostPreviewGrid.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: "",
};

export default BlogPostPreviewGrid;
